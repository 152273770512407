@import "variables/_variables-dir";
@import "helpers/_helpers-dir";

@import "containers/contacts/main-contacts";
@import "containers/contacts/contact-person";
@import "containers/contacts/contacts-slider";
@import "containers/bank-details";
@import "containers/contact-form";

button.gm-ui-hover-effect {
  display: none;
  visibility: hidden;
  opacity: 0; }
