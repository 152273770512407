.contacts-slider {

  .swiper-slide {
    max-width: 600px;
    cursor: grab;
    @include xs {
      max-width: 320px; }

    img {
      display: block;
      max-width: 100%; } }

  .swiper-pagination {
    position: static;
    margin-top: 16px;
    @include xs {
      margin-top: 12px; } }

  .swiper-pagination-bullet {
    margin: 0 4px; }

  .lazy-placeholder {
    min-height: 400px;
    @include xs {
      min-height: 200px; } } }
