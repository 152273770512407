.contact-form {
  padding: 32px 20px 60px;
  background-color: #F7F7F7;
  @include xs {
    padding-bottom: 40px; }

  &__container {
    max-width: 720px;
    margin: 0 auto; }

  &__tabs-wrapper {
    position: relative;
    margin-bottom: 15px;
    @include xs {
      margin-bottom: 20px; } }

  &__tabs {
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    width: auto; }

  &__tab {
    display: flex;
    align-items: baseline;
    width: auto;
    position: relative;
    padding: 17px 25px;
    background-color: $G-2;
    border: 1px solid $G-3;
    @include bold;
    font-size: 18px;
    color: $G-5;
    cursor: pointer;
    white-space: nowrap;
    @include sm {
      white-space: normal; }
    @include xs {
      font-size: 15px;
      padding: 18px 15px;
      text-align: center; }
    @include xxs {
      padding: 18px 10px; }

    &:hover:not(.is-active) {
      color: $red; }

    &.is-active {
      background-color: transparent;
      border-color: transparent;
      color: #000000;
      &:before {
        content: '';
        position: absolute;
        top: -1px;
        left: -1px;
        width: calc(100% + 2px);
        height: 2px;
        background-color: #000000; } } }

  &__note {
    text-align: right;
    font-size: 11px;
    color: $G-5; }

  &__form {
    margin-top: 11px;

    .form-group {

      &__fields {
        margin-bottom: 17px; }

      &__control {
        max-width: 320px; }

      &__message {
        margin-bottom: 7px;
        margin-top: 5px; } }

    .button {
      padding: 14px 16px; }

    .textarea {
      @include xs {
        height: 200px; } }

    .field {
      &.is-error {
        margin-bottom: 40px;

        .tooltip {
          display: inline-flex;
          min-width: 197px; }

        &.is-relative {
          margin-bottom: 28px;

          .tooltip {
            position: relative !important;
            top: 12px !important; } } } }

    .tooltip {
      max-width: 100%;

      &__arrow {
        left: 20px !important; } } } }
