.bank-details {

  &__title {
    margin-bottom: 11px;
    font-size: 28px;
    @include semi;
    @include xs {
      font-size: 22px; } }

  &__container {
    max-width: 920px; }

  table {
    max-width: 800px;

    tr {
      &:not(:last-child) {
        border-bottom: 1px solid $G-3; } }

    td {
      width: 50%;
      padding: 7px 4px 8px 0;
      @include xs {
        display: block;
        width: 100%;
        padding-bottom: 10px;
        padding-top: 4px; }

      &:first-child {
        @include bold;
        @include xs {
          padding: 10px 4px 0 0; } }

      span {
        font-size: 13px;
        margin-left: 5px;
        @include xs {
          display: block;
          margin-left: 0;
          margin-top: 5px; } } } } }
