.contact-person {
  $this: &;

  //&__list
  //  display: flex
  //  align-items: flex-start
  //  justify-content: center
  //  flex-wrap: wrap
  //  max-width: 900px
  //  margin: 0 auto

  &__item {
    max-width: 300px;
    margin: 0 auto;
    text-align: center;
    //+xl
    ////width: calc(100% / 3)
    @include md {
      //width: auto
      margin: 0 auto 36px; } }


  &__icon {
    font-size: 60px;
    margin-bottom: 18px;

    .icon {
      display: block;
      margin: 0 auto; } }

  &__title {
    margin-bottom: 6px;
    @include bold; }

  &__link {
    color: #000000;
    text-decoration: none;
    display: inline-block;
    margin-top: 5px;

    &:hover {
      color: $red;
      text-decoration: underline; } }

  .swiper-wrapper {
    &:not(.is-disabled) {
      #{$this}__item {
        margin-bottom: 45px; } } }

  .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: -10px;
    @include xs {
      bottom: 5px; } } }
