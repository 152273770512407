.main-contacts {
  margin-top: 32px;
  @include xs {
    margin-top: 20px; }

  &__title {
    text-align: center;
    font-size: 44px;
    @include extrabold;
    @include xs {
      font-size: 28px;
      @include bold; } }

  &__tabs-wrapper {
    position: relative;
    @include md {
      margin-right: -20px;
      margin-left: -20px; } }

  &__tabs-container {
    margin: 31px 36px 36px;
    @include md {
      margin: 25px 0 20px; } }

  &__tabs {
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    width: auto;
    @include md {
      flex-wrap: nowrap;
      justify-content: flex-start; }
    @include sm {
      justify-content: center; } }

  &__tab {
    display: flex;
    align-items: baseline;
    width: auto;
    position: relative;
    padding: 18px 50px;
    background-color: $G-2;
    border: 1px solid $G-3;
    @include bold;
    font-size: 18px;
    color: $G-5;
    cursor: pointer;
    @include md {
      padding: 6px 0 0;
      background-color: transparent;
      border: none;
      @include semi;
      color: $G-5;
      font-size: 16px;
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: $G-3; } }

    &:hover:not(.is-active) {
      color: $red; }

    &:not(:last-child) {
      @include md {
        margin-right: 24px; } }

    &:last-child {
      @include md {
        margin-right: 20px; } }

    &:first-child {
      @include md {
        margin-left: 20px; } }

    &.is-active {
      background-color: transparent;
      border-color: transparent;
      color: #000000;
      &:before {
        content: '';
        position: absolute;
        top: -1px;
        left: -1px;
        width: calc(100% + 2px);
        height: 2px;
        background-color: #000000;
        @include md {
          top: 0; } }
      &:after {
        @include sm {
          display: none; } } } }

  &__tab-descr {
    margin-top: -3px;
    font-weight: normal;
    font-size: 16px;
    @include sm {
      display: none; } }

  &__tab-content {
    display: none;
    max-width: 880px;
    margin: 0 auto;
    &.is-active {
      display: block; } }

  &__container {
    display: flex;
    height: 700px;
    @include md {
      display: block;
      height: auto;
      margin-left: -20px;
      margin-right: -20px;
      width: calc(100% + 40px); } }

  &__map-lazy {
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover; } }

  &__map-container {
    position: relative;
    width: 100%;
    height: 550px;
    margin-top: 30px;
    @include md {
      height: 500px; }
    @include xs {
      height: 400px;
      margin-bottom: 20px; }

    .lazy-placeholder {
      opacity: 0.5; } }

  &__map-init {
    width: 100%;
    height: 100%; }

  &__row {
    margin-top: 22px; }

  &__phone {
    position: relative;
    display: inline-flex;
    color: $red;
    font-size: 18px;
    @include semi;
    border-bottom: 1px solid rgba(198, 67, 68, 0.5);
    text-decoration: none;
    line-height: 1.2;

    &:not(:last-child) {
      margin-right: 24px;
      @include sm {
        margin-bottom: 10px; } }

    &:hover {
      border-color: transparent; }

    &.has-note {
      @include xs {
        margin-bottom: 20px; } } }

  &__note {
    position: absolute;
    width: auto;
    white-space: nowrap;
    font-size: 0.55em;
    color: #444;
    bottom: -14px; }

  &__email {
    display: inline-flex;
    margin-top: 2px;
    color: $red;
    font-size: 18px;
    border-bottom: 1px solid rgba(198, 67, 68, 0.5);
    text-decoration: none;
    line-height: 1.2;

    &:not(:last-child) {
      margin-right: 24px; }

    &:hover {
      border-color: transparent; } }

  &__text {
    font-size: 18px;
    line-height: 1.56;
    color: #222222; }

  &__address {
    font-size: 18px;
    line-height: 1.56; }

  &__subway {
    margin-top: -1px;
    display: flex;
    align-items: center;

    .icon {
      font-size: 24px;
      margin-right: 9px;
      margin-bottom: -6px; }

    &--green {
      .icon {
        fill: #138547; } } }

  &__place-descr {
    max-width: 320px;
    margin: 0 auto 32px;
    text-align: center;
    @include smUp {
      display: none; } } }
